import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Button, TextContainer, Paragraph, Ul } from "../../..";
import {
	Knobs,
	DocPageMeta,
	Example,
	InlineMessage,
	ImportExample,
	Code,
	Header,
	DocsTable,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { ColorGrayDarkest } from "../../../design-tokens/variables";
import { Spinner } from "./spinner";

export const Meta: DocPageMeta = {
	category: "Feedback",
	notepad: "https://hackmd.io/_a1i87t8TZakq3fCSSoqPA",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Spinner"
			subTitle="Provides visual feedback to the user that a process is underway when the duration is uncertain."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={Spinner} />
				<Header.H2>Spinner vs. Progress Bar</Header.H2>
				<Paragraph>
					To clarify the differences between <Code>Spinner</Code> and <Code>ProgressBar</Code>, a
					short description is provided.
				</Paragraph>
				<DocsTable>
					<thead>
						<tr>
							<th>Component name</th>
							<th>Purpose</th>
							<th>User interaction</th>
							<th>Usage</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Spinner</td>
							<td>Indicates an ongoing process with an unknown or indefinite duration.</td>
							<td>No user interaction is typically possible during the process.</td>
							<td>Loading content, refreshing data, background tasks.</td>
						</tr>
						<tr>
							<td>Progress Bar</td>
							<td>Shows the progress of a process with a known or estimated duration.</td>
							<td>User interaction may be possible during the process (e.g., cancellation).</td>
							<td>Uploading/downloading files, multi-step forms, progress towards goals.</td>
						</tr>
					</tbody>
				</DocsTable>

				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The Spinner component offers several properties to customize its appearance and behavior.
					The following sections illustrate these properties and explain their appropriate use
					cases.
				</Paragraph>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					Choose between <Code>dark</Code> or <Code>light</Code> variants to ensure contrast with
					the background.
				</Paragraph>
				<Example fn={defaultExample} />
				<Header.H3>Size</Header.H3>
				<Paragraph>
					Select <Code>small</Code>, <Code>medium</Code> (default), or <Code>large</Code> to fit the
					<Code>Spinner</Code> appropriately within its container.
				</Paragraph>
				<Example fn={sizeExample} />
				<Header.H3>Presentational</Header.H3>
				<Paragraph>
					Set the <Code>presentational</Code> property to <Code>true</Code> to hide the{" "}
					<Code>Spinner</Code>
					from assistive technologies like screen readers. Use this property when another UI element
					is already conveying the loading state to assistive technology users.
				</Paragraph>
				<Example fn={presentationalExample} />
				<Header.H3>Delayed Appearance</Header.H3>
				<Paragraph>
					In some cases, the action indicated by the <Code>Spinner</Code> might complete rapidly.
					This can lead to the <Code>Spinner</Code> appearing and disappearing quickly, causing UI
					flickering. To avoid this, set the <Code>appearanceDelay</Code> property to control the
					time (in milliseconds) the <Code>Spinner</Code> waits before becoming visible. The default
					delay is 200ms.
				</Paragraph>
				<Example fn={DelayedAppearanceExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs component={Spinner} initialProps={{ variant: "dark" }} />
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Spinner</Code> when
					</Paragraph>
					<Ul
						items={[
							<>The system is actively loading content or performing an action.</>,
							<>The duration of the action is unknown or unpredictable.</>,
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>Spinner</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							"Center of an interactive element e.g buttons.",
							<>
								Within content areas (
								<GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink>,{" "}
								<GatsbyLink to="/lab/components/Tables and lists/List table">List table</GatsbyLink>
								, <GatsbyLink to="/lab/components/Tables and lists/Table">Table</GatsbyLink>)/
							</>,
							<>
								<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink>/
								<GatsbyLink to="/lab/components/Overlay/Popover">Popover</GatsbyLink>.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>Spinner</Code> to existing components for visual consistency.
							</>,
							<>
								Set the <Code>size</Code> property to <Code>small</Code> when the Spinner's default{" "}
								<Code>medium</Code> size is too big for its container. Set the property to{" "}
								<Code>large</Code> when the default size is too small.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								Actions with a known or estimated duration (use a{" "}
								<GatsbyLink to="/lab/components/Data Visualization/Progress Bar">
									Progress Bar
								</GatsbyLink>
								).
							</>,
							<>Overuse can be visually distracting.</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Ensure sufficient color contrast between the Spinner and its background, adhering to WCAG guidelines.",
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Ul
						items={[
							<>
								Set the <Code>presentational</Code> property to <Code>true</Code>, when the
								Spinner's loading message is already communicated to assistive technology users by a
								different UI element.
							</>,
							<>Place the Spinner inside the container where the loading content will appear.</>,
							<>
								The container in which content will appear, should have{" "}
								<Code>aria-busy="true"</Code> while loading and <Code>aria-busy="false"</Code> when
								done loading.
							</>,
						]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							<>Use short, clear loading messages (e.g., "Loading...", "Working...").</>,
							<>
								For extended loading times, provide more context (e.g., "Loading results...",
								"Fetching data...").
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

/** @ignore [visual] */
const defaultExample = () => (
	<>
		<div style={{ padding: 16 }}>
			<Spinner variant="dark" />
		</div>
		<div style={{ padding: 16, background: ColorGrayDarkest }}>
			<Spinner variant="light" />
		</div>
	</>
);

/** @ignore [visual] */
const sizeExample = () => (
	<div style={{ display: "flex" }}>
		<Spinner variant="dark" size="large" />
		<Spinner variant="dark" size="medium" />
		<Spinner variant="dark" size="small" />
	</div>
);

/** @ignore [visual] */
const presentationalExample = () => <Spinner variant="dark" presentational />;

/** @ignore [visual] */
const DelayedAppearanceExample = () => {
	const [isVisible, setVisibility] = useState<boolean>(false);
	return (
		<div style={{ display: "flex" }}>
			<Button onClick={() => setVisibility(!isVisible)} variant="primary">
				{isVisible ? "Hide Spinner" : "Show Spinner"}
			</Button>
			{isVisible && <Spinner appearanceDelay={1000} variant="dark" />}
		</div>
	);
};
